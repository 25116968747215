<template>
  <div class="mt-2 mb-6 md:my-0 flex flex-col md:flex-row items-start">
    <SectionLeft />
    <div class="h-full md:h-screen w-full md:w-6/12 px-4 md:pr-16 lg:pr-32">
        <GuestinviteSection />
    </div>
    <DataserviceLogo />
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import GuestinviteSection from "../components/guestinvite/GuestinviteSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue"

export default {
  components: {
    SectionLeft,
    GuestinviteSection,
    DataserviceLogo
  },
};
</script>