<template>
  <div class="mt-4 md:mt-24 lg:mt-44 flex flex-col justify-center items-center">
    <div class="w-max relative">
      <div class="rounded-full bg-greyishWhite p-24"></div>
      <img
        class="h-28 mb-12 mx-auto md:mx-0 absolute top-8 left-9"
        src="@/assets/images/mail-icon-cl.png"
        alt="mail-icon-cl"
      />
    </div>
    <div class="mt-6">
      <div class="font-proximaMedium">
        <div class="flex flex-wrap justify-center">
          <h1 class="text-2xl">Invite Guest Users:</h1>
          <h1 class="ml-2 text-2xl">[Species] Pet Record: [Name]</h1>
          <!-- <h1 class="ml-2 text-2xl">All Pet Records</h1> -->
        </div>
        <p class="mt-2 my-6 text-sm">
          Invite guests to interact with your pets’ records by sharing the link
          below.
        </p>
      </div>
      <div class="relative font-proximaLight">
        <input
          class="
            w-full
            rounded-md
            px-4
            py-4
            my-1
            text-sm
            border border-white
            bg-transparent
            shadow-sm
          "
          type="text"
          :value="this.text"
          disabled
        />
        <button
          class="
            absolute
            top-3
            right-2
            w-max
            rounded
            bg-primary
            px-2
            py-2
            text-sm text-white
            cursor-pointer
          "
          @click="copyClipboard"
          v-if="!copied"
        >
          Copy Link
        </button>
        <button
          class="
            absolute
            top-3
            right-2
            w-max
            rounded
            bg-primary
            px-2
            py-2
            text-sm text-white
          "
          v-else
        >
          Copied!
        </button>
      </div>
      <div class="flex justify-center">
        <ul
          class="
            w-6/12
            mt-4
            font-proximaLight
            flex
            items-center
            justify-between
          "
        >
          <li class="w-5 h-5 bg-primary-20 rounded-full cursor-pointer">
            <MailIcon
              iconColor="#059AA3"
              width="20"
              height="20"
              class="ml-1 mt-1"
            />
          </li>
          <li class="w-5 h-5 bg-primary-20 rounded-full cursor-pointer">
            <MoreIcon
              iconColor="#059AA3"
              width="20"
              height="20"
              class="ml-1 mt-1"
            />
          </li>
          <li class="w-5 h-5 bg-primary-20 rounded-full cursor-pointer">
            <InstagramIcon
              iconColor="#059AA3"
              width="20"
              height="20"
              class="ml-1 mt-1"
            />
          </li>
          <li class="w-5 h-5 bg-primary-20 rounded-full cursor-pointer">
            <TwitterIcon
              iconColor="#059AA3"
              width="20"
              height="20"
              class="ml-1 mt-1"
            />
          </li>
          <li class="w-5 h-5 bg-primary-20 rounded-full cursor-pointer">
            <FacebookIcon
              iconColor="#059AA3"
              width="20"
              height="20"
              class="ml-1 mt-1"
            />
          </li>
          <li class="w-5 h-5 bg-primary-20 rounded-full cursor-pointer">
            <SendIcon
              iconColor="#059AA3"
              width="20"
              height="20"
              class="ml-1 mt-1"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MailIcon from "../icons/MailIcon.vue";
import FacebookIcon from "../icons/FacebookIcon.vue";
import InstagramIcon from "../icons/InstagramIcon.vue";
import TwitterIcon from "../icons/TwitterIcon.vue";
import MoreIcon from "../icons/MoreIcon.vue";
import SendIcon from "../icons/SendIcon.vue";

export default {
  components: {
    MailIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    MoreIcon,
    SendIcon,
  },
  data() {
    return {
      text: "https://perpetual.guest.com/farhadahmed",
      copied: false,
    };
  },
  methods: {
    copyClipboard() {
      navigator.clipboard.writeText(this.text);
      this.copied = true;
    },
  },
};
</script>

<style>
</style>