<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 8.66671C8.36811 8.66671 8.66659 8.36823 8.66659 8.00004C8.66659 7.63185 8.36811 7.33337 7.99992 7.33337C7.63173 7.33337 7.33325 7.63185 7.33325 8.00004C7.33325 8.36823 7.63173 8.66671 7.99992 8.66671Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6667 8.66671C13.0349 8.66671 13.3333 8.36823 13.3333 8.00004C13.3333 7.63185 13.0349 7.33337 12.6667 7.33337C12.2985 7.33337 12 7.63185 12 8.00004C12 8.36823 12.2985 8.66671 12.6667 8.66671Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.33341 8.66671C3.7016 8.66671 4.00008 8.36823 4.00008 8.00004C4.00008 7.63185 3.7016 7.33337 3.33341 7.33337C2.96522 7.33337 2.66675 7.63185 2.66675 8.00004C2.66675 8.36823 2.96522 8.66671 3.33341 8.66671Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>